import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import linkResolver from 'helpers/linkResolver';
import Link from 'gatsby-link';
import Logo from 'images/Wordmark-White.svg';

const MenuQuery = graphql`
  query {
    prismicGlobalLayout {
      data {
        footer_menu {
          link {
            url
            uid
            type
            id
          }
          link_title {
            html
            raw
            text
          }
        }
      }
    }
  }
`;

function Footer() {
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);
  return (
    <StaticQuery
      query={`${MenuQuery}`}
      render={data => (
        <Box
          component="footer"
          py={10}
          mt={20}
          style={{ backgroundColor: '#516572' }}
        >
          <Container maxWidth="xl">
            <Box component={Grid} container justifyContent="space-between">
              <Grid item xs={12} md={8}>
                <Box display="flex" justifyContent="center">
                  <Logo className="footer-brand" />
                </Box>

                <Box display="flex" mt={10} justifyContent="center">
                  {data.prismicGlobalLayout.data.footer_menu.map(
                    (item, key) => {
                      return (
                        <Box key={key} mr={2}>
                          <Link
                            to={linkResolver(item.link)}
                            className="no-underline"
                          >
                            <Typography
                              component="span"
                              variant="body2"
                              color="textSecondary"
                            >
                              {item.link_title.text}
                            </Typography>
                          </Link>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md="auto">
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
                  height="100%"
                  mt={{ xs: 6, md: 0 }}
                >
                  <Box className="footer-border-left" pl={{ xs: 0, md: 5 }}>
                    <Box>
                      <a
                        href="https://realestatecapitalholdings.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography
                          component="span"
                          variant="h3"
                          color="textSecondary"
                        >
                          Real
                          <br />
                          Estate
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={6}>
                      <a
                        href="https://realestatecapitalholdings.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-link"
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          color="textSecondary"
                          className="text-uppercase"
                        >
                          Capital
                          <br />
                          Holdings
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://www.hayfieldhomes.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          HAYFIELD
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://livingspacehousing.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          LIVING SPACE
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://www.merrion-holdings.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          MERRION
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://terrastrategic.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          TERRA
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://www.verdanthomes.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          Verdant
                        </Typography>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    />
  );
}

export default Footer;
