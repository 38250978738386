import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Consumer } from 'store/createContext';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Logo from 'images/logo-reverse.svg';
import Link from 'gatsby-link';
import Container from '@material-ui/core/Container';
import IconSml from 'images/Icon-BlueGrey.svg';
import linkResolver from 'helpers/linkResolver';

const Wrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 500;
  display: flex;
`;

const MenuQuery = graphql`
  query {
    prismicGlobalLayout {
      data {
        main_menu {
          link {
            id
            type
            uid
            url
          }
          link_title {
            html
            raw
            text
          }
        }
      }
    }
  }
`;

function MainNavigation() {
  return (
    <StaticQuery
      query={`${MenuQuery}`}
      render={data => (
        <Wrapper>
          <Container maxWidth={false}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={5}
              >
                <Consumer>
                  {({ toggleMenu }) => (
                    <button onClick={toggleMenu} className="standard-btn">
                      <CloseIcon />
                    </button>
                  )}
                </Consumer>
                <div>
                  <Link to="/">
                    <Logo className="brand" />
                  </Link>
                </div>
                <div>
                  <CloseIcon className="visibility-hidden" />
                </div>
              </Box>
              <Box display="flex" justifyContent="center">
                <Consumer>
                  {({ toggleMenu }) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      mb="auto"
                    >
                      {data.prismicGlobalLayout.data.main_menu.map(
                        (item, key) => {
                          return (
                            <Box key={key} mb={2}>
                              <Typography
                                component={Link}
                                to={linkResolver(item.link)}
                                onClick={toggleMenu}
                                className="text-uppercase main-nav-item"
                                color="textPrimary"
                                variant="h3"
                              >
                                {item.link_title.text}
                              </Typography>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  )}
                </Consumer>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                py={5}
                width="100%"
              >
                <IconSml className="icon-sml" />
              </Box>
            </Box>
          </Container>
        </Wrapper>
      )}
    />
  );
}

export default MainNavigation;
