import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .menuOpen {
    overflow: hidden;
  }
  .spaced-grid-wrapper {
    height: auto;
    overflow: hidden;
  }
  .brand {
    width: 225px;
  }
  .visibility-hidden {
    visibility: hidden;
  }
  .icon-sml {
    width: 100px;
  }
  .spacer {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .font-weight-bold {
    font-weight: 700;
  }
  .social-icon {
    color: #fff;
    font-size: 2rem;
  }
  img {
    width: 100%;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .brand-logo {
    height: 50px;
    width: auto;
  }
  .footer-brand {
    width: 350px;
  }
  .height-full {
    height: 100%;
  }
  p {
    margin-top: 0;
  }
  .standard-text-link {
    text-decoration: none;
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 3px;
      background: #fff;
      width: 100%;
    }
  }
  .standard-text-link-text {
    padding: 0 20px;
  }
  .full-underline {
    border-bottom: 2px solid #6a7985;
    line-height: 60px;
  }
  .icon-xsml {
    height: auto;
    width: 25px;
  }
  .caption-body .block-img {
    max-width: 250px;
    height: auto;
  }
  .download-link {
    text-decoration: none;
    color: #6a7985;
  }
  .standard-btn {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
    cursor: pointer;
  }
  .main-nav-item {
    text-decoration: none;
  }
  .text-no-underline {
    text-decoration: none;
  }
  .light-grey-text {
    color: #8E8E8D;
  }
  .white-bg {
    background-color: #fff;
  }
  .gateway-bg {
    background-color: #fff;
  }
  [data-reach-dialog-overlay] {
    z-index: 100;
  }
  .iframe-video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  /* Then style the iframe to fit in the container div with full height and width */
  .iframe-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 10rem);
  }
  .block-img {

  }
  .form {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  #outlined-basic-label, 
  .MuiInputLabel-outlined.MuiInputLabel-shrink,
  .MuiInputLabel-outlined {
    color: #6a7985;
  } 
  .btn-default {
    background-color: #6a7985;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: #fff;
    font-size: 1.2rem;
    padding: 10px 20px;
  }
  .MuiFormLabel-root {
    font-size: 1.2rem;
  }
  .footer-link {
    text-decoration: none;
  }
  .no-underline {
    text-decoration: none;
  }
  .icon-white {
    color: #fff;
  }
  .icon-grey {
    color: #516572;
  }
  .news-article-box {
    border: 1px solid #6a7985;
    height: 100%;
  }
  .footer-border-left {
    @media only screen and (min-width: 960px) {
      border-left: 1px solid #fff;
    }
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .group-btn {
    display: inline-flex;
    align-items: center;
  }
  .text-primary {
    color: #6a7985;
  }
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .MuiInputLabel-shrink {
    top: -10px;
  }
  .text-red {
    color: #ba000d;
  }
  .text-white-highlight {
    background: #fff;
    padding: 10px 20px;
  }
`;
