import React from 'react';
import { Link } from 'gatsby';
import posed from 'react-pose';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'images/Wordmark-BlueGrey.svg';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Consumer } from 'store/createContext';
import Headroom from 'react-headroom';
import Typography from '@material-ui/core/Typography';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = () => (
  <AnimatedContainer>
    <Headroom>
      <Container maxWidth={false} className="gateway-bg">
        <Box
          component={Grid}
          container
          display="flex"
          alignItems="center"
          py={5}
        >
          <Grid item xs>
            <Consumer>
              {({ toggleMenu }) => (
                <button onClick={toggleMenu} className="standard-btn">
                  <MenuIcon className="icon-grey" />
                </button>
              )}
            </Consumer>
          </Grid>
          <Grid item xs className="text-center">
            <Link to="/">
              <Logo className="brand" />
            </Link>
          </Grid>
          <Grid item xs className="text-right">
            <Consumer>
              {({ toggleGroupMenu }) => (
                <button
                  onClick={toggleGroupMenu}
                  className="standard-btn group-btn"
                >
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    GROUP
                  </Typography>
                  <Box pl={2}>
                    <MenuIcon className="icon-grey" />
                  </Box>
                </button>
              )}
            </Consumer>
          </Grid>
        </Box>
      </Container>
    </Headroom>
  </AnimatedContainer>
);

export default Header;
